import E401 from "../../components/error-pages/E401"
import Header from "../../components/error-pages/errorheader"
import Layout from "../../components/layout"
import React from "react"

const E401Page = props => {
  const staticContent = props.pageContext.staticContent;
  const staticImages = props.pageContext.staticImages;

  return (
    <Layout>
      <Header />
      <E401 staticContent={staticContent} staticImages={staticImages}/>
    </Layout>
  )
}

export default E401Page;