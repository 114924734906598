import "./error.scss"

import { Link } from 'gatsby'
import React from "react"
import beeKeeper from "../../../static/images/error/beekeeper.svg"
import { getStaticImageByIdentifier } from "../../services/image.service";
import { getStaticContentByIdentifier } from "../../services/misc.service";

export default (props) => {
  return (
    <section className="margin-t-0-xs margin-t-6-md margin-b-6">
      <div className="hero row middle-md margin-t-2-xs margin-t-6-md">
        <div className="col-xs-12 col-md-6 img-col">
            <img className="error-illustration" alt="error message" src={getStaticImageByIdentifier('E401.image', props.staticImages)} />
        </div>
        <div className="col-xs-12 col-md-6 text-center">
            <h1>{getStaticContentByIdentifier('E401.title', props.staticContent)}</h1>
            <p>{getStaticContentByIdentifier('E401.content', props.staticContent)}</p>
            <p className="error-text">Error Code: 401</p>
            <Link to="/">
              <button className="error-button">{getStaticContentByIdentifier('Error.button', props.staticContent)}</button>
            </Link>
        </div>
      </div>
    </section>
  )
}